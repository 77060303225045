import React, {memo, useRef} from 'react';
import {useOverlayTriggerState} from '@react-stately/overlays';

import {type UserProfile} from '@mattermost/types/users';
import {imageURLForUser, isMobile} from 'utils/utils';
import {isGuest} from 'mattermost-redux/utils/user_utils';

import ProfilePopover from 'components/profile_popover';
import BotBadge from 'components/widgets/badges/bot_badge';
import GuestBadge from 'components/widgets/badges/guest_badge';
import SharedUserIndicator from 'components/shared_user_indicator';
import {PopoverOverlay} from '@time-webkit/all/molecules/popover';

import styles from './user_profile.module.css';

export type UserProfileProps = {

    /**
     * id родительского элемента, нужно указать, если UserProfile располагается внутри модалки
     */
    appendId?: string;
    userId: string;
    displayName?: string;
    isBusy?: boolean;
    isShared?: boolean;
    overwriteName?: React.ReactNode;
    overwriteIcon?: string;
    user?: UserProfile;
    disablePopover?: boolean;
    displayUsername?: boolean;
    hasMention?: boolean;
    hideStatus?: boolean;
    isRHS?: boolean;
    overwriteImage?: React.ReactNode;
    channelId?: string;
    onProfilePopoverOpen?: () => void;
    onProfilePopoverClose?: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({
    disablePopover = false,
    displayName,
    displayUsername = false,
    hasMention = false,
    hideStatus = false,
    isRHS = false,
    overwriteName = '',
    overwriteIcon,
    user,
    userId = user?.id,
    channelId,
    appendId,
    onProfilePopoverOpen,
    onProfilePopoverClose,
    isShared = false,
}) => {
    const triggerRef = useRef<HTMLButtonElement>(null);
    const popoverState = useOverlayTriggerState({
        onOpenChange: (isOpen) => {
            if (isOpen) {
                onProfilePopoverClose?.();
            } else {
                onProfilePopoverOpen?.();
            }
        },
    });

    let name: React.ReactNode;
    if (user && displayUsername) {
        name = `@${(user.username)}`;
    } else {
        name = overwriteName || displayName || '...';
    }

    const ariaName: string = typeof name === 'string' ? name.toLowerCase() : '';

    if (disablePopover) {
        return <div className='user-popover'>{name}</div>;
    }

    let placement: React.ComponentProps<typeof PopoverOverlay>['placement'] = 'top';
    if (isRHS && !isMobile()) {
        placement = 'left';
    }

    let profileImg = '';
    if (user) {
        profileImg = imageURLForUser(user.id, user.last_picture_update);
    }

    let sharedIcon;
    if (isShared) {
        sharedIcon = (
            <SharedUserIndicator
                className='shared-user-icon'
                withTooltip={true}
            />
        );
    }

    return (
        <React.Fragment>
            <PopoverOverlay
                containerPadding={10}
                offset={10}
                placement={placement}
                state={popoverState}
                triggerNode={triggerRef.current}
                shouldCloseOnInteractOutside={(element) => element !== triggerRef.current}
            >
                <ProfilePopover
                    className={styles.popover}
                    appendId={appendId}
                    userId={userId}
                    channelId={channelId}
                    src={profileImg}
                    hide={popoverState.close}
                    hideStatus={hideStatus}
                    isRHS={isRHS}
                    hasMention={hasMention}
                    overwriteName={overwriteName}
                    overwriteIcon={overwriteIcon}
                />
            </PopoverOverlay>
            <button
                ref={triggerRef}
                aria-label={ariaName}
                className='style--none'
                onClick={popoverState.toggle}
            >
                {name}
            </button>
            {sharedIcon}
            <BotBadge
                show={Boolean(user && user.is_bot)}
                className='badge-popoverlist'
            />
            <GuestBadge
                show={Boolean(user && isGuest(user.roles))}
                className='badge-popoverlist'
            />
        </React.Fragment>
    );
};

export default memo(UserProfile);
