import {createPortal} from 'react-dom';
import {useEffect, useRef} from 'react';
import type {PropsWithChildren} from 'react';

import {useEventCallback, useOnClickOutside} from 'usehooks-ts';

import {DropdownContainer, BorderRadiusSize} from './dropdown-container';

import {useDropdownPosition} from './use-dropdown-position';

type Props = Omit<Parameters<typeof useDropdownPosition>[0], 'dropdownRef' | 'isOpen'> & {
    open?: boolean;
    borderRadiusSize?: BorderRadiusSize;
}

export const Dropdown = ({
    open,
    onClose,
    children,
    borderRadiusSize = BorderRadiusSize.Medium,
    ...props
}: PropsWithChildren<Props>) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownProps = useDropdownPosition({...props, dropdownRef, isOpen: open});

    useOnClickOutside(dropdownRef, () => {
        onClose?.();
    });

    const handleKeyDown = useEventCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape' && open) {
            onClose?.();
        }
    });

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const dropdown = createPortal(
        <DropdownContainer
            {...dropdownProps}
            onMouseDown={(e) => e.preventDefault()}
            ref={dropdownRef}
            borderRadiusSize={borderRadiusSize}
        >
            {children}
        </DropdownContainer>,
        document.body,
    );

    return open ? dropdown : null;
};

