import React, {useEffect, useRef, useState} from 'react';
import type {SuggestionProps} from '@tiptap/suggestion';

import {ListBox, ListBoxItem} from '@time-webkit/all/atoms/listbox';
import {Spinner} from '@time-webkit/all/atoms/spinner';
import {Button, BUTTON_SIZE} from '@time-webkit/all/atoms/button';

import {Elevation} from '@time-webkit/all/atoms/elevation';
import {PopoverOverlay} from '@time-webkit/all/molecules/popover';
import classNames from 'classnames';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {Constants} from 'utils/constants';

import {useEventCallback} from 'usehooks-ts';

import {useIntl} from 'react-intl';

import styles from './styles.module.css';
import {useGetMentions} from './useGetMentions';

import type {Mention} from './types';

type MentionListProps<M extends Mention> = {
    getMentions: (query: string) => Promise<M[]>;
    renderMentionOption: (mention: M) => React.ReactNode;
    onSelect: (mention: M, index: number) => void;
    maxHeight?: number;
    calcMaxHeight?: (mentions: M[]) => number;
    ignoreEmptyQuery?: boolean;
} & Pick<SuggestionProps<M>, 'query' | 'command' | 'decorationNode'>;

const TryAgain: React.FunctionComponent<{retry: () => void}> = (props) => {
    const {formatMessage} = useIntl();
    const typographyCls = useTypography({
        size: TypographySize.BodyL,
    });
    const buttonLabel = formatMessage({id: 'apps.error.try_again', defaultMessage: 'Try again'});

    return (
        <div className={styles.errorWrapper}>
            <span className={typographyCls}>
                {formatMessage({id: 'apps.error.smth_went_wrong', defaultMessage: 'Something went wrong'})}
            </span>
            <Button
                size={BUTTON_SIZE.SMALL}
                onPress={props.retry}
                aria-label={buttonLabel}
            >
                {buttonLabel}
            </Button>
        </div>
    );
};

/* 8px top padding, 4 list items and just enough of 5th */
const DEFAULT_LIST_MAX_HEIGHT = 8 + (36 * 4) + (36 * 0.6);

export const MentionList = <M extends Mention, >(props: MentionListProps<M>) => {
    const {
        query, getMentions, command, decorationNode, calcMaxHeight,
        renderMentionOption, onSelect, maxHeight = DEFAULT_LIST_MAX_HEIGHT,
        ignoreEmptyQuery = true,
    } = props;

    const {error, loading, mentions, retry} = useGetMentions<M>(query, getMentions, ignoreEmptyQuery);

    const listBoxRef = useRef<HTMLUListElement>(null);

    const [selected, setSelected] = useState(0);

    const shouldShowPopover = mentions.length > 0 || loading;
    const [isOpen, setOpen] = useState(shouldShowPopover);

    const onKeyDown = useEventCallback((e: KeyboardEvent) => {
        // Если результатов нет, то список эмоджей не отображается и обрабатывать ничего не нужно
        if (!mentions.length) {
            return;
        }

        if (e.key === Constants.KeyCodes.UP[0]) {
            e.preventDefault();
            e.stopPropagation();

            if (selected === 0) {
                return;
            }
            setSelected(selected - 1);
        }

        if (e.key === Constants.KeyCodes.DOWN[0]) {
            e.preventDefault();
            e.stopPropagation();

            if (selected === mentions.length - 1) {
                return;
            }
            setSelected(selected + 1);
        }

        if (e.key === Constants.KeyCodes.ENTER[0]) {
            handleSelect(new Set([mentions[selected]?.handle as string]));
            e.preventDefault();
            e.stopPropagation();
        }
    });

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        listBoxRef.current?.children.item(selected)?.scrollIntoView?.({behavior: 'instant', block: 'nearest'});
    }, [selected]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };
    }, [onKeyDown]);

    useEffect(() => {
        if (mentions.length > 0 || loading) {
            setOpen(true);
        }
    }, [mentions, loading]);

    const handleSelect = React.useCallback<Required<React.ComponentProps<typeof ListBox>>['onSelectionChange']>(
        (keys) => {
            for (const entry of keys) {
                const foundMentionIndex = mentions.findIndex((mention) => mention.handle === entry);
                const foundMention = mentions[foundMentionIndex];

                if (foundMention) {
                    onSelect(foundMention, foundMentionIndex);
                    command(foundMention);
                }
            }
        },
        [command, mentions, onSelect],
    );

    if (!shouldShowPopover) {
        /**
         * В маттермосте в такой ситуации вообще не показывается меню
         */
        return null;
    }

    const Wrapper = (props: {children: React.ReactNode; maxHeight?: number}) => (
        <PopoverOverlay
            isOpen={isOpen}
            onOpenChange={setOpen}
            triggerNode={decorationNode ?? undefined}
            placement='top start'
            maxHeight={props.maxHeight}
        >
            {props.children}
        </PopoverOverlay>
    );

    if (loading) {
        return (
            <Wrapper key='loading'>
                <Elevation>
                    <div className={styles.elevation}>
                        <Spinner />
                    </div>
                </Elevation>
            </Wrapper>
        );
    }

    if (error) {
        return (
            <Wrapper key='error'>
                <Elevation>
                    <div className={styles.elevation}>
                        <TryAgain retry={retry} />
                    </div>
                </Elevation>
            </Wrapper>
        );
    }

    return (
        <Wrapper maxHeight={maxHeight}>
            <ListBox
                style={{
                    maxHeight: calcMaxHeight ? calcMaxHeight(mentions) : maxHeight,
                }}
                className={styles.listWrapper}
                onSelectionChange={handleSelect}
                items={mentions}
                ref={listBoxRef}
                selectionMode='single'
                shouldFocusWrap={true}
                aria-label='List'
            >
                {(item: M) => {
                    return (
                        <ListBoxItem key={item.handle} aria-label={item.displayName || item.handle}>
                            <div className={classNames({[styles.optionFocused]: item.handle === mentions[selected]?.handle})}>
                                {renderMentionOption(item)}
                            </div>
                        </ListBoxItem>
                    );
                }}
            </ListBox>
        </Wrapper>
    );
};
